<template>
  <b-form-group
    label="Şase No"
    label-for="chasis"
  >
    <validation-provider
      #default="{ errors }"
      name="Şase No"
      rules="required"
    >
      <b-input-group>
        <b-form-input
          id="chasis"
          v-model="dataItem.chasis"
          placeholder="Şase"
        />
        <b-input-group-append>
          <b-button
            variant="warning"
            :disabled="!dataItem.id"
            @click="stockSync"
          >
            <FeatherIcon icon="RefreshCwIcon" />
            Stok Bilgisi Getir
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Chasis',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
  },
  methods: {
    stockSync() {
      this.$store.dispatch('stock/stockSync', { chasis: this.dataItem.chasis })
        .then(response => {
          if (response.status) {
            this.showAlert({
              title: 'İşlem Başarılı!',
              text: 'Stok verisi güncellendi. Bilginizi kontrol edip kaydedebilirsiniz.',
              variant: 'success',
            })
            this.dataItem.id_com_brand = response.data.id_com_brand
            this.dataItem.model = response.data.model
            this.dataItem.invoice_date = response.data.invoice_date
            this.dataItem.chasis = response.data.chasis
            this.dataItem.hardware = response.data.hardware
            this.dataItem.invoice_no = response.data.invoice_no
            this.dataItem.myear = response.data.myear
            this.dataItem.engine = response.data.engine
            this.dataItem.cc = response.data.cc
            this.dataItem.purchase_price = response.data.purchase_price
          } else {
            this.showAlert({
              title: 'İşlem Hatası!',
              text: response.error,
              variant: 'danger',
            })
          }
        })
    },
    showAlert(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: 'InfoIcon',
          text: data.text,
          variant: data.variant,
        },
      })
    },
  },
}
</script>
